<template>
  <div id="app" class="groupnew">
    <Nav />
    <div class="mt50">
      <img :src="require(`@/assets/banner/banner-05${$i18n.locale == 'zh'?'z':'e'}.png`)" />
      <div class="group flex wrap between">
        <div class="group-hz mb-15" v-for="item in teamList" :key="item.id">
          <img class="group-img" :src="`${$baseUrl}${item.thumb}`" alt="" />
          <p class="group-p1">{{ item.title }}</p>
          <a :href="`${$baseUrl}${item.file}`" download>
            <div class="group-but f-12">点击下载</div>
          </a>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<style scoped>
</style>
<script>
import Nav from "@/components/nav.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "App",
  components: {
    Nav,
    Footer,
  },
  data() {
    return {
      teamList: [],
    };
  },
  computed: {},
  mounted() {
    console.log(36, this.$baseUrl);
    this.group();
  },
  methods: {
    // 团讯列表
    group() {
      this.$ajax.get("/cms/api/tuanxunlist", {}).then((res) => {
        if (res.code == "200") {
          this.teamList = res.data;
        }
      });
    },
    // 下载
    download(val) {
      if (!val.file) {
        return;
      }
      // 1
      let url = window.URL.createObjectURL(new Blob([`${this.$baseUrl}${val.file}`]))
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download',val.title)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      // 2
      // var iframe = document.createElement("iframe");
      // iframe.style.display = "none";
      // iframe.src = `${this.$baseUrl}${val.file}`;
      // document.body.appendChild(iframe);
      // 3
      // var x = new XMLHttpRequest()
      // x.open('GET',`${this.$baseUrl}${val.file}`,true)
      // x.responseType = 'blob'
      // x.onload = function(){
      //   var url = window.URL.createObjectURL(x.response)
      //   var a = document.createElement('a')
      //   a.href = url
      //   a.download = val.title
      // }
      // x.send()
    },
  },
};
</script>

<style lang="scss" scoped>
.groupnew{
  img{
    max-width: 100%;
  }
}
.group {
  width: 100%;
  margin-top: 24px;
  padding: 0 12px;
  box-sizing: border-box;
  .group-hz {
    width: 48%;
    text-align: center;
    background-color: #634636;
    color: #fff;
    padding-bottom: 15px;
    .group-img {
      width: 100%;
      vertical-align: top;
    }
    .group-p1 {
      font-size: 14px;
      margin: 18px 0 12px;
    }
    .group-but {
      width: 68px;
      height: 20px;
      border: 1px solid #ffffff;
      border-radius: 4px;
      line-height: 20px;
      text-align: center;
      margin: 0 auto;
      color: #fff;
    }
  }
}
</style>

